/* eslint-disable */
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper } from '_helpers';

const examAtom = atom({
    key: 'exam',
    default: true
});

const examDataAtom = atom({
    key: 'examData',
    default: {}
});

export { useExamModal };
const { REACT_APP_API_URL } = process.env;
function useExamModal() {
    const baseUrl = `${REACT_APP_API_URL}`;
    const fetchWrapper = useFetchWrapper();
    const [isModal, setExamModalState] = useRecoilState(examAtom);
    const [examModalData, setExamModalDataState] = useRecoilState(examDataAtom);

    return {
        setExamModal,
        setDisableExamModal,
        isModal: isModal,
        examModalData: examModalData
    }


    function setExamModal({ url, datas }) {
        setExamModalState(false);
        setExamModalDataState({ url, datas });
    }

    function setDisableExamModal() {
        setExamModalState(true)
        setExamModalDataState({});
    }
}
