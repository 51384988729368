/* eslint-disable */
import { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper } from '_helpers';

export { useCourse };

const courseByCategoryAtom = atom({
    key: 'courseByCategoryAtom',
    default: []
});

const { REACT_APP_API_URL } = process.env;
function useCourse() {
    const baseUrl = `${REACT_APP_API_URL}`;
    const fetchWrapper = useFetchWrapper();

    //const setMenu = useSetRecoilState(systemMuenAtom);
    //const menuValue = useRecoilValue(systemMuenAtom);

    const [categories, setCategories] = useRecoilState(courseByCategoryAtom);


    useMemo(() => {
        //  getCategories();
    }, []);



    return {
        getCategories,
        categories: categories,
    }

    async function getCategories(category) {
        let req =
        {
            criteria: {
                CategoryCode: category
            },
            pageIndex: 0,
            pageSize: 24
        }

        return fetchWrapper.post(`${baseUrl}/products/categories`, req)
            .then(response => {
                console.log(response?.data)
                setCategories(response?.data);
            });
    }



}