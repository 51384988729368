/* eslint-disable */
import "./homepage.scss";

import { useRecoilValue } from 'recoil';
import { authAtom, usersAtom } from '../../_state';
import { useUserActions } from '../../_actions';

import { v4 as uuid } from 'uuid';
import { useState, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import Row from "../../components/Row/Row";
import Row2 from "../../components/Row/Row2";
import Credits from "../../components/Credits/Credits";
import { useRetrieveData } from "../../hooks/useRetrieveData";
import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../motionUtils";
import { useRetrieveCourse } from '../../hooks/useRetrieveCourse';
import { useCourse } from "hooks/useCourse";
import i18n from 'i18next';

// import Posts from "../../hooks/Posts";
// import { useQuery } from "react-query";
// import axios from "axios";

// async function fetchPosts() {
//   const { data } = await axios.get(
//     "https://e-learning-api.de-vz.com/api/products/3fa85f64-5717-4562-b3fc-2c963f66afa6/3fa85f64-5717-4562-b3fc-2c963f66afa6"
//   );
//   return data;
// }

const Homepage = () => {
  const courses = useCourse();

  // for banner
  const rows = useRetrieveData("movies");

  useEffect(() => {
    courses.getCategories("")
  }, [])


  const posters = {
    posterUrl: `https://mylearn-api.devz.sbs/images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg`,
    posterLargeUrl: `https://mylearn-api.devz.sbs/images/products/elearning/MoEKaPFHABtA1xKoOteirGaHl1.jpg`
  }


  const categories = [
    {
      id: uuid(),
      categoryName: "กำลังนิยม",
      poster: posters,
      isLarge: false
    },
    {
      id: uuid(),
      categoryName: "กำลังเรียน",
      poster: posters,
      isLarge: true

    },
    {
      id: uuid(),
      categoryName: "มาใหม่",
      poster: posters,
      isLarge: false
    },
    {
      id: uuid(),
      categoryName: "หลักสูตรบังคับ",
      poster: posters,
      isLarge: false
    },
    {
      id: uuid(),
      categoryName: "กลุ่มที่สนใจ",
      poster: posters,
      isLarge: false
    }
  ]



  const imgCov = [
    '/7WJjFviFBffEJvkAms4uWwbcVUk.jpg',
    '/nprqOIEfiMMQx16lgKeLf3rmPrR.jpg',
    '/dq18nCTTLpy9PmtzZI6Y2yAgdw5.jpg',
    '/yizL4cEKsVvl17Wc1mGEIrQtM2F.jpg',
    '/AjQgFtfXTmmMVuaH2VfQDdGbeQH.jpg',
    '/j28p5VwI5ieZnNwfeuZ5Ve3mPsn.jpg',
    '/xXHZeb1yhJvnSHPzZDqee0zfMb6.jpg',
    '/wjQXZTlFM3PVEUmKf1sUajjygqT.jpg',
    '/nDLylQOoIazGyYuWhk21Yww5FCb.jpg',
    '/lzLzKXq2C0kL5Pu7VW5sNl5KV6L.jpg',
  ];

  //console.log(data);
  // if (course.data) {
  //   allCourse = course.data.map((course) => {
  //     let randomNumber = Math.floor(Math.random() * 10);
  //     let coverRandom = imgCov[randomNumber];

  //     return {
  //       adult: false,
  //       backdrop_path: coverRandom,
  //       genre_ids: [16, 28, 18, 878],
  //       id: course.id,
  //       original_language: "th",
  //       original_title: course.productNameLocale,
  //       overview: course.productDescription.substr(0,499)+'...',
  //       popularity: 220.065,
  //       poster_path: coverRandom,
  //       release_date: "2021-08-13",
  //       title: course.productNameLocale,
  //       video: false,
  //       vote_average: course.productRating,
  //       vote_count: Math.floor(Math.random() * 100),
  //       isFavourite: false,
  //     };
  //   });

  //   dataRow = {
  //     id: 10,
  //     title: "Course",
  //     genre: "course",
  //     isLarge: undefined,
  //     data: course.data.data,
  //   };

  // }

  // const movieItem = [
  //   {
  //     adult: false,
  //     backdrop_path: "/1EAxNqdkVnp48a7NUuNBHGflowM.jpg",
  //     genre_ids: [16, 28, 18, 878],
  //     id: 283566,
  //     original_language: "ja",
  //     original_title: "シン・エヴァンゲリオン劇場版:||",
  //     overview:
  //       "In the aftermath of the Fourth Impact, stranded without their Evangelions, Shinji, Asuka, and Rei find refuge in one of the rare pockets of humanity that still exist on the ruined planet Earth. There, each of them live a life far different from their days as an Evangelion pilot. However, the danger to the world is far from over. A new impact is looming on the horizon—one that will prove to be the true end of Evangelion.",
  //     popularity: 220.065,
  //     poster_path: "/jDwZavHo99JtGsCyRzp4epeeBHx.jpg",
  //     release_date: "2021-08-13",
  //     title: "Evangelion: 3.0+1.0 Thrice Upon a Time",
  //     video: false,
  //     vote_average: 8.7,
  //     vote_count: 264,
  //     isFavourite: false,
  //   }
  // ];



  //master

  //detail
  // const { item, item: { title, original_name, original_title, name, genre_ids, poster_path, backdrop_path }, isLarge, isFavourite } = result;

  return (
    <>

      {courses?.categories &&
        <motion.div
          className="Homepage"
          variants={defaultPageFadeInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <Banner />



          {courses?.categories?.map(cat => (
            <Row2 key={cat.productCategoryId}
              datas={cat.items} title={i18n.language == "en" ? cat.productCategoryName : cat.productCategoryNameLocale} genre={""} isLarge={cat.isLarge} poster={posters}
            />
          ))}



          {/* <Row2 key="no1111"
      datas={course.data}  title={"กำลังเรียน"} genre={""} isLarge = {false}
      />
      <Row2 key="no1111"
      datas={course.data}  title={"มาใหม่"} genre={""} isLarge = {false}
      />
      <Row2 key="no1111"
      datas={course.data}  title={"บังคับหรือแนะนำ"} genre={""} isLarge = {false}
      />
      <Row2 key="no1111"
      datas={course.data}  title={"กลุ่มที่สนใจ"} genre={""} isLarge = {false}
      /> */}

          {/* <Row key='asihd9a8sd' {...testRow} /> */}

          {/* {rows && rows.map((props) => <Row key={props.id} {...props} />)} */}
          <Credits />
        </motion.div>}
    </>
  );
};

export default Homepage;
