/* eslint-disable */
import axios, { AxiosResponse } from 'axios';
import config from './api-config';
import { setUserInfo, getUserInfoAsync, clearUserInfo } from '../common/session-storeage';

const api = axios.create({
    baseURL: config.apiEndpoint,
    timeout: config.apiTimeout
});


api.interceptors.request.use(async (config) => {
    const userInfo = await getUserInfoAsync();

    //console.log(" xxxx userInfo xxxx");
    //console.log(userInfo);

    // console.log(userInfo.token)


    // const access_token = await refreshAccessToken();  //

    if (userInfo) {
        config.headers['Authorization'] = 'Bearer ' + userInfo.token;
    }



    return config;
}, error => {
    return Promise.reject(error);
});


api.interceptors.response.use(async (response) => {
    return response;
}, async (error) => {
    const userInfo = await getUserInfoAsync();

    const response = error.response;
    const originalRequest = error.config;



    if (response && response.status === 401) {



        originalRequest._retry = true;
        console.log(userInfo);
        // console.log('refresh token url >> ' + config.apiEndpoint);
        // console.log('token url >> ' + userInfo.token);
        // console.log('refresh token is >>' + userInfo.refreshToken);

        //const refreshToken = window.localStorage.getItem('refreshToken');
        return axios.post(config.apiEndpoint + 'api/Account/refresh', { refreshToken: userInfo.refreshToken, accessToken: userInfo.token })
            .then((res) => {

                // console.log("datadatadatadatadata", res)
                //window.localStorage.setItem('token', data.token);
                //window.localStorage.setItem('refreshToken', data.refreshToken);

                // console.log("data from refresh =>>>>>>>>>>", data);
                // console.log("data.accessToken =>>>>>>>>>>", data.accessToken);
                // console.log("data.accessToken =>>>>>>>>>>", data.data.accessToken);

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data.accessToken;
                originalRequest.headers['Authorization'] = 'Bearer ' + res.data.data.accessToken;

                userInfo.token = res.data.data.accessToken;
                userInfo.refreshToken = res.data.data.refreshToken;

                console.log("set userInfo=>>>", userInfo);
                setUserInfo(userInfo);

                return axios(originalRequest);
            })
            .catch(error => {

                // console.log("errorrrrrrrrrr return Promise.reject",error.response.status);
                // console.log("errorrrrrrrrrr return Promise.reject",error.response.data);
                clearUserInfo();
                window.location.href = "/home";
                return Promise.reject(error);
            });


    }

    if (response && response.status === 400 &&
        response.data && response.data.errors) {
        return Promise.reject(response.data.errors);
    }


    if (response && response.status === 400 &&
        response.data && response.data.title) {
        return Promise.reject(response.data.title + '\n' + response.data.detail);
    }


    if (response && response.status === 409 &&
        response.data && response.data.title) {
        return Promise.reject(response.data.title + '\n' + response.data.detail);
    }





    return Promise.reject(error);
});


const confighdr = { headers: {'Content-Type': 'application/json'} };
// axios.put(url, content, config).then(response => {
//     ...
// });


export default {
    getAllCourse: (request) => api.get('api/products/8e776e27-296c-4358-8b9d-dfed868e8a33/8e776e27-296c-4358-8b9d-dfed868e8a33')

}