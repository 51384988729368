/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  //   CardTitle,
  //   CardText,
  Row,
  Col,
  Table,
  CardImg,
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { showModalPlay } from "../../redux/playmodal/modal.actions";
import { useDispatch } from "react-redux";
//import { Link, NavLink } from "react-router-dom";
import i18n from "i18next";
import "./style.scss";
import "./lessonButton.scss"
import { usePlayVideo } from "hooks/usePlayVideo";
import { useExamModal } from "hooks/useExamModal";

import { useAuthen } from "hooks/useAuthen";
import { useHistory } from "react-router-dom";
import { FaPlay, FaCheckDouble, FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

import LessonBox from "./LessonBox";

const CourseDetail = (props) => {
  const playerActions = usePlayVideo();
  const examModal = useExamModal();
  const userActions = useAuthen();
  const history = useHistory();

  const { course, isRegister } = props;
  const [activeTab, setActiveTab] = useState("1");
  const { t } = useTranslation();
  const getLanguage = () => i18next.language || window.localStorage.i18nextLng;
  const dispatch = useDispatch();

  const handlePlayModalOpening = () => {
    // let datas = {
    //   url: _url,
    //   datas: {}
    // };
    // playerActions.setPlay(datas);
  };

  const tabs = [
    {
      tabId: "1",
      tanName: t("couse_description_label"),
    },
    {
      tabId: "2",
      tanName: t("course_lesson_and_test_label"),
    },
    // {
    //   tabId: "3",
    //   tanName: 'Exams',
    // },
    {
      tabId: "3",
      tanName: t("course_teacher_label"),
    },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //console.log("CourseDetail >>", props);
  return (
    <div className="body-text">
      <Nav tabs>
        {tabs.map((tab) => (
          <NavItem>
            <NavLink
              className={classnames(
                { active: activeTab == tab.tabId },
                "nav_link"
              )}
              onClick={() => {
                toggle(tab.tabId);
              }}
            >
              {tab.tanName}
            </NavLink>
          </NavItem>
        ))}

        {/*
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" }, 'nav_link')}
            onClick={() => {
              toggle("1");
            }}
          >
            {t('couse_description_label')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" }, 'nav_link')}
            onClick={() => {
              toggle("2");
            }}
          >
            {t('course_lesson_and_test_label')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" }, 'nav_link')}
            onClick={() => {
              toggle("3");
            }}
          >
            {t('course_teacher_label')}
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="p-3">
          <Row>
            <Col sm="12">
              {course?.courseContents?.map((itm) => (
                <p className="text-black">
                  <h3 className="">
                    {i18n.language == "en"
                      ? itm.contentName
                      : itm.contentNameLocale}
                  </h3>
                  {i18n.language == "en"
                    ? itm.contentDescription
                    : itm.contentDescriptionLocale}
                </p>
              ))}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2" className="p-3">
          <Row>
            <Col xl="12">
              <Card body>
                <Table className="">
                  {/* <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                    </tr>
                  </thead> */}
                  <tbody>
                    {course?.lessons?.map((lesson) => (
                      <tr>
                        <th scope="row" className="t" width="200">
                          <Button
                            color="link"
                            key={lesson.id}
                            onClick={() => {
                              if (lesson.type == 1)
                                if (userActions?.auth) {
                                  if (isRegister) {
                                    // is already register
                                    let datas = {
                                      url: lesson?.videoUrl,
                                      datas: {
                                        courseId: course.id,
                                        lessonId: lesson.lessonId,
                                      },
                                    };

                                    playerActions.setPlay(datas);
                                  }
                                } else {
                                  history.push("/login");
                                }
                            }}
                          >
                            {i18n.language == "en"
                              ? lesson.lessonName
                              : lesson.lessonNameLocale}
                          </Button>
                        </th>
                        <td>
                          <div className="Lesson-info--icon icon--play">
                            <FaCheckDouble />
                          </div>

                          <Link
                            className="Lesson-info--icon icon--play"
                            //	onClick={handlePlayModalOpening}
                            onClick={() => {
                              if (userActions?.auth) {
                                if (isRegister) {
                                  // is already register
                                  let datas = {
                                    url: lesson?.videoUrl,
                                    datas: {
                                      courseId: course.id,
                                      lessonId: lesson.lessonId,
                                      examId: lesson.examId,
                                    },
                                  };
                                  if (lesson.type == 1) {
                                    playerActions.setPlay(datas);
                                  } else {
                                    examModal.setExamModal(datas);
                                  }
                                }
                              } else {
                                history.push("/login");
                              }
                            }}
                            to={"#"}
                          //to={`/course`}
                          >
                            {lesson.type == 1 ? <FaPlay /> : <FaEdit />}
                          </Link>
                        </td>
                        <td>
                          {i18n.language == "en"
                            ? lesson.lessonTitle
                            : lesson.lessonTitleLocale}
                        </td>
                      </tr>
                    ))}
                    {/*
                    <tr>
                      <th scope="row" className="text-primary" width="150">บทเรียนที่ 1</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-primary">บทเรียนที่ 2</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-primary">บทเรียนที่ 2</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-primary">บทเรียนที่ 2</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-primary">บทเรียนที่ 2</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr> */}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </TabPane>
        {/* <TabPane tabId="3" className="p-3">
          <Row>

            <Col sm="12">
              <LessonBox />
            </Col>
          </Row>
        </TabPane> */}
        <TabPane tabId="3" className="p-3">
          <Row>
            <Col sm="3">
              <Card className="border-0">
                <CardImg
                  top
                  width="100%"
                  src="https://mylearn-api.devz.sbs/images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg"
                  alt="Card image cap"
                />
              </Card>
            </Col>
            <Col sm="9">
              <p className="text-black">
                <h3>{course?.teacher?.teacherName}</h3>
                {course?.teacher?.teacherDescription}
              </p>
              <p className="text-black">
                <h4 className="">หลักสูตรที่สอน</h4>
                {course?.teacher?.courses.map((c) => (
                  <>
                    {c.courseName}
                    <br />
                  </>
                ))}
              </p>
              <p className="text-black">
                <h4 className="">ข้อมูลการติดต่อ</h4>

                <Table>
                  <tbody>
                    <tr>
                      <th scope="row" className="text-black">
                        Email
                      </th>
                      <td className="text-black">
                        {course?.teacher?.contract?.email}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-black">
                        เบอร์โทร
                      </th>
                      <td className="text-black">
                        {course?.teacher?.contract?.phone}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-black">
                        มือถือ
                      </th>
                      <td className="text-black">
                        {course?.teacher?.contract?.mobile}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </p>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CourseDetail;
