/* eslint-disable */
// import 'bootstrap/dist/css/bootstrap.css';
import "./style.scss";
import React, { useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  CardImg,
  CardBody,
  CardText,
  ButtonToggle,
} from "reactstrap";
import UserInfomation from "components/UserInfomation";

import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useRecoilValue } from "recoil";
import { courseAtom } from "_state";
import { useCourseActions } from "_actions";
import { useMyCourse } from "hooks/useMyCourse";
import { useAuthen } from "hooks/useAuthen";

// import { motion } from "framer-motion";
// import { defaultPageFadeInVariants } from "../../motionUtils";






const UserProfile = () => {

  //const { courseId } = useParams();
  const courseId = '09f064d8-1f74-4c52-b70a-e0af724f854a';
  const { t } = useTranslation();

  const courseActions = useCourseActions();
  //const course = courseActions.Courses;
  const course = useRecoilValue(courseAtom);
  const auth = useAuthen();
  const mycourse = useMyCourse();
  //const courseActions = useCourseActions();



  const summaryData = [
    {
      id: uuid(),
      name: 'ชื่อ-สกุล',
      value: 'อ.เดวิด เฮลด์.'
    },
    {
      id: uuid(),
      name: 'ตำแหน้งงาน',
      value: 'พนังงาน'
    },
    {
      id: uuid(),
      name: 'จำนวนคอสทั้งหมดที่เรียน',
      value: '100 คอส'
    },
    {
      id: uuid(),
      name: 'ระยะเวลาสะสม',
      value: '1000 ชั่วโมง'
    },
    {
      id: uuid(),
      name: 'กำลังเรียน',
      value: '20 คอส'
    },
    {
      id: uuid(),
      name: t('course_lastupdate_label'),
      value: '10/10/2021'
    }
  ];


  // useMemo( () => {
  //   const courseActions = useCourseActions();
  //   const courseData = await courseActions.getAll();
  //   console.log(courseData)
  // }, [])


  useEffect(() => {
    courseActions.getCourse(courseId); //"fe1be5f3-5fa6-4c8b-8416-b72331b0ee81"

    //console.log(mycourse?.courses);

    //course = courseActions.Courses;
  }, [])


  useEffect(() => {
    console.log("mycourse");
    console.log(mycourse?.courses);

    mycourse.isRegister(courseId)

    //course = courseActions.Courses;
  }, [mycourse?.courses])



  useEffect(() => {

    //console.log(course())
    console.log(course)
  }, [course])


  return (
    <Container fluid className="Course pt-5">
      <Row>
        <Col xl="9">
          {course &&
            <UserInfomation course={course} />}
        </Col>
        <Col xl="3">
          <div>
            <Card className="border-0">
              <CardImg
                top
                width="100%"
                src="https://mylearn-api.devz.sbs/images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg"
                alt="Card image cap"
              />
              <CardBody>
                <CardText>
                  <ListGroup flush>
                    {summaryData.map(itm => (
                      <ListGroupItem className="bg-transparent" tag="a" href="#">
                        <span>{itm.name}</span>
                        <span className="float-right">{itm.value}</span>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardText>

              </CardBody>
            </Card>
          </div>
          <div className="text-center m-5">
            {/* {(auth.auth && course) &&
              <ButtonToggle color={!mycourse?.isregister ? "primary" : "secondary"} className="btn-lg"
                disabled={mycourse?.isregister}
                onClick={() => mycourse?.registerCourse(course?.id)}
              >
                {t(mycourse?.isregister ? 'couse_already_register_label' : 'couse_register_label')}
              </ButtonToggle>} */}
          </div>
        </Col>
      </Row>
    </Container >
  );
};

export default UserProfile;
