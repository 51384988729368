/* eslint-disable */
import { Container, Row, Col } from "reactstrap";

import "./auth.scss";
import { useState } from "react";
import SignIn from "../../components/SignIn/SignIn";
import SignUp from "../../components/SignUp/SignUp";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  staggerOne,
  authFadeInUpVariants,
  modalVariants,
  authPageFadeInVariants,
} from "../../motionUtils";
import { LOGO_URL, SIGNIN_BGIMG_URL, LOGO_WELCOME } from "../../requests.js";
import { useSelector } from "react-redux";
import { selectAuthErrors } from "../../redux/auth/auth.selectors";

const Auth = () => {
  const [isSignedUp, setIsSignedUp] = useState(true);
  const authError = useSelector(selectAuthErrors);

  return (
    <Container fluid>
      <Row className="m-0">
        <div className="Auth__opacityLayer" />
        <div
          className="Auth__bgLayer"
          style={{ backgroundImage: `url(${SIGNIN_BGIMG_URL})` }}
        />

        <Col
          lg="7"
          className="d-flex justify-content-center align-items-center"
        >
          <img src={LOGO_WELCOME} className="img-fluid" />
        </Col>
        <Col lg="5" className="">
          <motion.div
            className="Auth"
            variants={authPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {/* <Link to="/" className="Auth__logo">
				<img className="Auth__logo--img" src={LOGO_URL} alt="Fakeflix_logo" />
			</Link> */}
            <motion.div
              className="Auth__content"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <motion.div
                variants={staggerOne}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <motion.div
                  variants={authFadeInUpVariants}
                  className="Auth__content--title"
                >
                  {isSignedUp ? "เข้าสู่ระบบ" : "ลงทะเบียน"}

                  <span
                    className="text-danger float-right"
                    onClick={() => setIsSignedUp(!isSignedUp)}
                    style={{ cursor: "pointer" }}
                  >
                    <small>{isSignedUp ? "ลงทะเบียน" : "เข้าสู่ระบบ"}</small>
                  </span>
                </motion.div>

                {/* <motion.small
                  variants={authFadeInUpVariants}
                  className="Auth__content--disclaimer"
                >
                  {`Pay attention: this is not the original Netflix ${
                    isSignedUp ? "sign in" : "sign up"
                  }. Don't insert your real credentials here!`}
                </motion.small> */}
                {isSignedUp ? <SignIn /> : <SignUp />}
                {authError && (
                  <motion.p
                    variants={authFadeInUpVariants}
                    className="Auth__content--errors"
                  >
                    {authError}
                  </motion.p>
                )}
                {/* <motion.hr
                  variants={authFadeInUpVariants}
                  className="Auth__content--divider"
                />
                <motion.small
                  variants={authFadeInUpVariants}
                  className="Auth__content--toggleView"
                >
                  {isSignedUp
                    ? `Haven't you registered yet? `
                    : "Do you already have an account? "}
                </motion.small> */}
              </motion.div>
            </motion.div>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default Auth;
