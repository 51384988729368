/* eslint-disable */
import React, { createRef, useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  //   CardTitle,
  //   CardText,
  Row,
  Col,
  Table,
  CardImg,
  CardBody,
  Form,
} from "reactstrap";
import FormInput from "components/FormInput";
import FormGroupInput from "components/FormGroupInput"
import InputField from "components/InputField/InputField";
import Flex from "components/Flex"
import classnames from "classnames";
import { useTranslation } from 'react-i18next';
import { showModalPlay } from "../../redux/playmodal/modal.actions";
import { useDispatch } from "react-redux";
//import { Link, NavLink } from "react-router-dom";
import i18n from 'i18next';
import "./style.scss";
import { usePlayVideo } from "hooks/usePlayVideo";
import { useAuthen } from "hooks/useAuthen";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";


const cerificatesData = [
  {
    seq: 1,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  },
  {
    seq: 2,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 3,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 4,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 5,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 6,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 7,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 8,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 9,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 10,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 11,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 12,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 13,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 14,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 15,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 16,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 17,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 18,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 19,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 20,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 21,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  }, {
    seq: 22,
    date: '2021-10-01 09:30',
    courseName: ' Course Name xxxxxxx',
    revisionNo: '1.0',
    positin: " Position XXX",
    department: " Department XXX",
    team: " Team XXXX",
    jobDescription: "Job Description XXX",
    dayLeft: ' 30 days remaining'
  },
]



const UserInfomation = (props) => {
  let table = createRef();
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const playerActions = usePlayVideo();
  const userActions = useAuthen();
  const history = useHistory();

  const { course } = props;
  const [activeTab, setActiveTab] = useState("1");
  const { t } = useTranslation();
  const getLanguage = () => i18next.language || window.localStorage.i18nextLng;
  const dispatch = useDispatch();

  const handlePlayModalOpening = () => {
    // let datas = {
    //   url: _url,
    //   datas: {}
    // };
    // playerActions.setPlay(datas);
  }

  const Submit = (data) => {

  };


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const columns = [
    {
      dataField: 'date',
      text: 'Status',
      classes: 'py-2 align-middle',
      sort: true
    },
    {
      dataField: "courseName",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "revisionNo",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "positin",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "department",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "team",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "jobDescription",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "dayLeft",
      text: "",
      className: "text-white",
      align: "right",
    },
  ];


  //console.log("CourseDetail >>", props);
  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" }, 'nav_link')}
            onClick={() => {
              toggle("1");
            }}
          >
            User Informations
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" }, 'nav_link')}
            onClick={() => {
              toggle("2");
            }}
          >
            Cerificates
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" }, 'nav_link')}
            onClick={() => {
              toggle("3");
            }}
          >
            กำลังเรียน
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "4" }, 'nav_link')}
            onClick={() => {
              toggle("4");
            }}
          >
            ผ่านแล้ว
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "5" }, 'nav_link')}
            onClick={() => {
              toggle("5");
            }}
          >
            {t('course_teacher_label')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="p-3">
          <CardBody className="card-body">

            <Form onSubmit={handleSubmit(Submit)}>
              <Row>
                <Col lg={12}>
                  <FormInput className="card-body" control={control} as={FormGroupInput} id={`FirstName`} name={`FirstName`} label="FirstName" />
                </Col>
                <Col lg={12}>
                  <FormInput className="card-body" control={control} as={FormGroupInput} id={`Name`} name={`Name`} label="Name" />
                </Col>
                <Col lg={12}>
                  <FormInput className="card-body" control={control} as={FormGroupInput} id={`NameLocale`} name={`NameLocale`} label="NameLocale" />

                </Col>
                <Col lg={12}>
                  <FormInput className="card-body" control={control} as={FormGroupInput} id={`firstName`} name={`firstName`} label="firstName" />
                </Col>
                <Col lg={12}>
                  <FormInput className="card-body" control={control} as={FormGroupInput} id={`lastName`} name={`lastName`} label="lastName" />

                </Col>
                {/* <Col lg={12}>
                                    <FormInput control={control} as={CheckboxInput} id="isCompulsory" name="isCompulsory" label="Option 1"
                                        onClick={isCompulsoryChange}
                                    />
                                    <FormInput control={control} as={CheckboxInput} id="isNew" name="isNew" label="Option 2"
                                        onClick={isNewChange}
                                    />
                                    <FormInput control={control} as={CheckboxInput} id="isPoppular" name="isPoppular" label="Opion 3"
                                        onClick={isPoppularChange}
                                    />
                                </Col> */}

                <Col tag={Flex} xs={12} justify="end">




                </Col>
              </Row>
            </Form>
          </CardBody>
        </TabPane>
        <TabPane tabId="2" className="p-3">
          <Row>
            <Col xl="12">
              <Card body>
                <Table className="text-white">
                  {/* <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                    </tr>
                  </thead> */}
                  <tbody>

                    {cerificatesData.map(itm => (
                      <tr>
                        {/* <th scope="row" className="text-primary" width="200">
                          <Button color="link" key={itm.seq}
                            onClick={() => { }}
                          >{itm.seq}.)  {itm.date}</Button>

                        </th> */}
                        <td>{itm.date}</td>
                        <td>{itm.courseName}</td>
                        <td>{itm.revisionNo}</td>
                        <td>{itm.positin}</td>
                        <td>{itm.department}</td>
                        <td>{itm.team}</td>
                        <td>{itm.jobDescription}</td>
                        <td>{itm.dayLeft}</td>
                      </tr>
                    ))}

                    {/*
                    <tr>
                      <th scope="row" className="text-primary" width="150">บทเรียนที่ 1</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-primary">บทเรียนที่ 2</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-primary">บทเรียนที่ 2</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-primary">บทเรียนที่ 2</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-primary">บทเรียนที่ 2</th>
                      <td>ความมั่งคั่ง : ใครๆก็อยากมี</td>
                    </tr> */}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3" className="p-3">
          <Row>
            <Col xl="12">
              <Card body>
                <BootstrapTable
                  ref={table}
                  bootstrap4
                  keyField="id"
                  //   data={orders}
                  data={cerificatesData}
                  columns={columns}
                  selectRow={() => { }}
                  hideSelectColumn={true}
                  bordered={false}
                  classes="table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap card-body"
                  rowStyle={{ color: 'white' }}
                  rowClasses="btn-reveal-trigger"
                  headerClasses="bg-200 text-900"
                // {...paginationTableProps}
                />
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4" className="p-3">
          <Row>
            <Col xl="12">
              <Card body>
                <Table className="text-white">
                  <tbody>

                    {cerificatesData.map(itm => (
                      <tr>
                        <td>{itm.courseName}</td>
                        <td>{itm.revisionNo}</td>
                        <td>{itm.positin}</td>
                        <td>{itm.department}</td>
                        <td>{itm.team}</td>
                        <td>{itm.jobDescription}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5" className="p-3">
          <Row>
            <Col sm="3">
              <Card className="border-0">
                <CardImg
                  top
                  width="100%"
                  src="https://mylearn-api.devz.sbs/images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg"
                  alt="Card image cap"
                />
              </Card>
            </Col>
            <Col sm="9">
              <p className="text-white">
                <h3>{course?.teacher?.teacherName}</h3>
                {course?.teacher?.teacherDescription}
              </p>
              <p className="text-white">
                <h4 className="text-primary">หลักสูตรที่สอน</h4>
                {course?.teacher?.courses.map(c => (
                  <>
                    {c.courseName}
                    <br />
                  </>
                ))}

              </p>
              <p className="text-white">
                <h4 className="text-primary">ข้อมูลการติดต่อ</h4>

                <Table>
                  <tbody>
                    <tr>
                      <th scope="row" className="text-white">Email</th>
                      <td className="text-white">{course?.teacher?.contract?.email}</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-white">เบอร์โทร</th>
                      <td className="text-white">{course?.teacher?.contract?.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-white">มือถือ</th>
                      <td className="text-white">{course?.teacher?.contract?.mobile}</td>
                    </tr>
                  </tbody>
                </Table>
              </p>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default UserInfomation;
