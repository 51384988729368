/* eslint-disable */
import "./rowPoster.scss";
import { useState } from "react";
import { BASE_IMG_URL, FALLBACK_IMG_URL, BASE_API_URL } from "../../requests";
import { useDispatch } from "react-redux";
import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";
import { FaPlus, FaMinus, FaPlay, FaChevronDown } from "react-icons/fa";
import useGenreConversion from "../../hooks/useGenreConversion";
import { showModalDetail } from "../../redux/modal/modal.actions";
import { showModalPlay } from "../../redux/playmodal/modal.actions";

//import { useRecoilValue } from "recoil";
//import { playerAtom } from "_state";
//import { usePlayerActions } from "_actions";
import { usePlayVideo } from "hooks/usePlayVideo";

import { Link } from "react-router-dom";

const RowPoster2 = ({ title, code, description, poster, isLarge, courseId }) => {
	const playerActions = usePlayVideo();
	const item = {
		title: title,
		overview: 'xxxx',
		original_name: '',
		original_title: '',
		name: '',
		genre_ids: '',
		poster_path: `${'images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg'}`,
		titleImageUrl: `${BASE_API_URL}/${'images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg'}`,
		//  backdrop_path:`${BASE_API_URL}/${'images/products/elearning/chris-montgomery-smgTvepind4-unsplash.jpg'}` \
	};

	let fallbackTitle = title;//|| original_title || name || original_name;
	let genres = ["xxxx", "yyyy", "zzzz"]
	//let isLarge = true;
	let isFavourite = false;
	let overview = description?.substring(0, 248) + '...';
	//let code = "GO112T9V82";

	//const genresConverted = useGenreConversion(genre_ids);
	const dispatch = useDispatch();

	const handleAdd = event => {
		event.stopPropagation();
		//dispatch(addToFavourites({ ...item, isFavourite }));
	};
	const handleRemove = event => {
		event.stopPropagation();
		//dispatch(removeFromFavourites({ ...item, isFavourite }));
	};
	const handlePlayModalOpening = () => {
		playerActions.setPlay({});
	}

	const handleModalOpening = () => {
		dispatch(showModalDetail({ item, courseId, code, description, fallbackTitle, genres, isFavourite, overview, poster }));
	}

	const handlePlayAction = event => {
		event.stopPropagation();

	};

	return (
		<>
			<div
				className={`Row__poster ${isLarge && "Row__poster--big"}`}
			//onClick={handleModalOpening}
			>
				{isLarge ? (

					<img src={poster?.posterLargeUrl} alt={fallbackTitle} />

				) : (
					<img src={poster?.posterUrl} alt={fallbackTitle} />
				)}
				<div className="Row__poster-info">
					<div className="Row__poster-info--iconswrp">
						<Link
							className="Row__poster-info--icon icon--play"
							//	onClick={handlePlayModalOpening}
							onClick={handlePlayAction}
							to={'/play'}
						//to={`/course`}
						>
							<FaPlay />
						</Link>
						{!isFavourite
							? (
								<button className='Row__poster-info--icon icon--favourite' onClick={handleAdd}>
									<FaPlus />
								</button>
							) : (
								<button className='Row__poster-info--icon icon--favourite' onClick={handleRemove}>
									<FaMinus />
								</button>
							)}
						<button className='Row__poster-info--icon icon--toggleModal'>
							<FaChevronDown onClick={handleModalOpening} />
						</button>
						{/* <button className='Row__poster-info--icon icon--toggleModal'>
							<FaChevronDown onClick={handlePlayModalOpening} />
						</button> */}
					</div>
					<div className="Row__poster-info--title">
						<h3>{title}</h3>
					</div>
					<div className="Row__poster-info--genres">
						{genres && genres.map(genre => (
							<span key={`Genre--id_${genre}`} className="genre-title">{genre}</span>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default RowPoster2;