/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import InputField from "../InputField/InputField";

const FormGroupInput = ({ id, label, ...rest }) => (
    <FormGroup>
        <Label htmlFor={id}>{label}</Label>
        <InputField id={id} {...rest} />
        {/* <Input id={id} {...rest} /> */}
    </FormGroup>
);

FormGroupInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    ...Input.propTypes
};

export default FormGroupInput;
