/* eslint-disable */
import "./homepage.scss";
import { useState, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import Row from "../../components/Row/Row";
import Row2 from "../../components/Row/Row2";
import Credits from "../../components/Credits/Credits";
import { useRetrieveData } from "../../hooks/useRetrieveData";
import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../motionUtils";
import { useNavigate, useParams } from "react-router-dom";
import { useCourse } from "hooks/useCourse";
import i18n from 'i18next';

const CategoryPage = () => {
  let { categoryId } = useParams();
  const courses = useCourse();
  let allCourse;
  let dataRow;

  // for banner
  const rows = useRetrieveData("popular");

  useEffect(() => {
    courses.getCategories(categoryId)
  }, [categoryId])


  const posters = {
    posterUrl: `https://mylearn-api.devz.sbs/images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg`,
    posterLargeUrl: `https://mylearn-api.devz.sbs/images/products/elearning/MoEKaPFHABtA1xKoOteirGaHl1.jpg`
  }


  const imgCov = [
    '/7WJjFviFBffEJvkAms4uWwbcVUk.jpg',
    '/nprqOIEfiMMQx16lgKeLf3rmPrR.jpg',
    '/dq18nCTTLpy9PmtzZI6Y2yAgdw5.jpg',
    '/yizL4cEKsVvl17Wc1mGEIrQtM2F.jpg',
    '/AjQgFtfXTmmMVuaH2VfQDdGbeQH.jpg',
    '/j28p5VwI5ieZnNwfeuZ5Ve3mPsn.jpg',
    '/xXHZeb1yhJvnSHPzZDqee0zfMb6.jpg',
    '/wjQXZTlFM3PVEUmKf1sUajjygqT.jpg',
    '/nDLylQOoIazGyYuWhk21Yww5FCb.jpg',
    '/lzLzKXq2C0kL5Pu7VW5sNl5KV6L.jpg',
  ];

  return (
    <>

      {courses?.categories &&
        <motion.div
          className="Homepage"
          variants={defaultPageFadeInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <Banner />


          {courses?.categories?.map(cat => {
            if (cat.items.length > 0)
              return (
                <Row2 key={cat.productCategoryId}
                  datas={cat.items} title={i18n.language == "en" ? cat.productCategoryName : cat.productCategoryNameLocale} genre={""} isLarge={cat.isLarge} poster={posters}
                />
              )
          })}


          {/* {categories.map(cat => (
            <Row2 key={cat.categoryId}
              datas={course.data} title={cat.categoryName} genre={""} isLarge={cat.isLarge} poster={cat.poster}
            />
          ))} */}



          {/* <Row2 key="no1111"
      datas={course.data}  title={"กำลังเรียน"} genre={""} isLarge = {false}
      />
      <Row2 key="no1111"
      datas={course.data}  title={"มาใหม่"} genre={""} isLarge = {false}
      />
      <Row2 key="no1111"
      datas={course.data}  title={"บังคับหรือแนะนำ"} genre={""} isLarge = {false}
      />
      <Row2 key="no1111"
      datas={course.data}  title={"กลุ่มที่สนใจ"} genre={""} isLarge = {false}
      /> */}

          {/* <Row key='asihd9a8sd' {...testRow} /> */}

          {/* {rows && rows.map((props) => <Row key={props.id} {...props} />)} */}
          <Credits />
        </motion.div>}
    </>
  );
};

export default CategoryPage;
