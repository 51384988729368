/* eslint-disable */
import { useSetRecoilState, useRecoilValue } from 'recoil';

import { history, useFetchWrapper } from '_helpers';
import { courseAtom } from '_state';

export { useCourseActions };

const { REACT_APP_API_URL } = process.env;

function useCourseActions() {
    const baseUrl = `${REACT_APP_API_URL}`;
    const fetchWrapper = useFetchWrapper();
    //const setAuth = useSetRecoilState(authAtom);
    const setCourse = useSetRecoilState(courseAtom);

    return {
        getAll,
        getCourse,
        Courses
    }

    function getCourse(id) {
        return fetchWrapper.get(`${baseUrl}/courses/${id}`)
            .then(response => {
                setCourse(response);
            });
    }

    function Courses() {
        return useRecoilValue(courseAtom);
    };


    function getAll() {
        return fetchWrapper.get(`${REACT_APP_API_URL}/courses/fe1be5f3-5fa6-4c8b-8416-b72331b0ee81`).then(setCourse);
    }
}


// export const useWishlistState = () => {
//     return useRecoilState(wishlistAtom);
//   };

//   export const useWishlistStateValue = () => {
//     return useRecoilValue(wishlistAtom);
//   };

//   export const useSetWishlistState = () => {
//     return useSetRecoilState(wishlistAtom);
//   };

// usage = const allWishlist = useWishlistStateValue();