/* eslint-disable */
import { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper } from '_helpers';

export { useSysMenu };

const systemMuenAtom = atom({
    key: 'systemMenus',
    default: []
});

const { REACT_APP_API_URL } = process.env;
function useSysMenu() {
    const baseUrl = `${REACT_APP_API_URL}`;
    const fetchWrapper = useFetchWrapper();

    //const setMenu = useSetRecoilState(systemMuenAtom);
    //const menuValue = useRecoilValue(systemMuenAtom);

    const [menu, setMenu] = useRecoilState(systemMuenAtom);


    useMemo(() => {
        getMenu();
    }, []);



    return {
        getMenu,
        menus: menu,
    }

    function getMenu() {

        let req =
        {
            criteria: {
                searchText: "FRONTEND"
            },
            pageIndex: 0,
            pageSize: 0
        }

        return fetchWrapper.post(`${baseUrl}/sys-menus`, req)
            .then(response => {
                console.log(response?.data)
                setMenu(response?.data);
            });
    }



}